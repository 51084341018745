@import "../../../../styles/tools";

.BasicDateInput {
  width: 15rem;
  position: relative;

  & > .BasicDateInput__input {
    width: 100%;
    padding: 0.25rem 1.5rem 0.25rem 0.25rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid $defaultColor20;
    background-color: $whiteColor100;
    color: $defaultColor90;
    cursor: pointer;

    &:focus {
      border-color: $primaryColor60;
    }

    &.BasicDateInput__input__invalid {
      background-color: $secondaryColor10;
      border-color: $secondaryColor100;
    }

    &.BasicDateInput__input__valid {
      background-color: $validColor10;
      border-color: $validColor100;
    }

    &.BasicDateInput__input__disabled {
      background-color: $defaultColor10;
      color: $defaultColor40;
      cursor: default;
    }
  }

  & > .BasicDateInput__icon__container {
    @include flexCenter();
    position: absolute;
    right: 0.25rem;
    top: 0;
    width: 1rem;
    height: 100%;

    & > .BasicDateInput__icon {
      transition: transform 200ms;
      cursor: pointer;

      &.BasicDateInput__icon__disabled {
        cursor: default;

        & > path {
          stroke: $defaultColor20;
        }
      }
    }
  }
}

.BasicDateInput__calendar_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 16rem;
  height: 16.25rem;
  border-radius: 4px;
  border: 1px solid $defaultColor20;
  background-color: $whiteColor100;
  padding: 0.25rem;
  z-index: $optionsZIndex;
}
