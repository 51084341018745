@import "../../../../styles/tools";

$fadeHeight: 2rem;
$vPadding: 1.5rem;
$hPadding: 1rem;

.AsideList {
  width: 100%;
  height: 100%;
  background-color: $whiteColor100;
  border-radius: 4px;
  border: 1px solid $defaultColor20;
  padding: $vPadding $hPadding $vPadding #{2 * $hPadding};
  position: relative;

  & > .filterContainer {
    padding-bottom: 4px;
    width: 100%;
    height: 29px;
  }

  & > .listContainer {
    width: 100%;
    position: relative;
    height: calc(100% - 29px);

    & .list {
      padding: 1rem 0;

      & .listItem {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        padding-right: 1.5rem;
        margin-left: 2px;
        transition: box-shadow 200ms;

        & .itemIconBox {
          @include flexCenter();
          display: block;
          width: 1.5rem;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          padding: 0.25rem;
          transform-origin: center;
          transform: rotateZ(0);
          transition: transform 200ms;
        }

        &.groupItem {
          &.listItemOpened {
            box-shadow: 2px 2px 5px $transparentDarkColor35;
            z-index: 100;
            font-weight: bold;

            & .itemIconBox {
              transform: rotateZ(-90deg);
            }
          }
        }
      }

      & .itemInactive {
        color: $defaultColor20;
      }
    }
  }
}
