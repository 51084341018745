@import "../../../../styles/tools";

.BasicInput {
  width: 15rem;
  padding: 0.25rem;
  outline: none;
  border-radius: 4px;
  border: 1px solid $defaultColor20;
  background-color: $whiteColor100;
  color: $defaultColor90;

  &:focus {
    border-color: $primaryColor60;
  }

  &.BasicInput__invalid {
    background-color: $secondaryColor10;
    border-color: $secondaryColor100;
  }

  &.BasicInput__valid {
    background-color: $validColor10;
    border-color: $validColor100;
  }

  &.BasicInput__disabled {
    background-color: $defaultColor10;
    color: $defaultColor40;
  }
}