@import "../../../styles/tools";

.PatientListModal {
  @include flexCenter();
  background-color: $transparentDarkColor85;

  & .list {
    height: 10rem;
  }

  & .errorStatus,
  & .errorDateTime
  {
    font-size: 0.85rem;
    font-style: italic;
  }
}
