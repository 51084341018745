@import "../../../../styles/tools";

.UlcerEtiology {
  width: 100%;

  & > .legend {
    font-size: 0.85rem;
    color: $defaultColor90;
    padding: 0.25rem;
  }

  & > .content {
    @include flexRow(wrap, flex-start, flex-start);
    width: 100%;

    & > .item {
      width: 13rem;
      margin: 0 1rem;
    }
  }

  & > .errorMessage {
    width: 100%;
    height: 1.25rem;
    padding: 0.25rem 0;
    color: $secondaryColor100;
    font-weight: bold;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.invalid {

  }

  &.valid {

  }

  &.disabled {
    & > .legend {
      color: $defaultColor40;
    }
  }
}
