@import "../../styles/tools";

.Grid {
  &.container {
    display: flex;
    width: 100%;

    &.row {
      flex-direction: row;
      // width: 100%;
      padding: 0.5rem 0;
    }

    &.column {
      flex-direction: column;
    }

    &.wrap {
      flex-wrap: wrap;
    }

    &.nowrap {
      flex-wrap: nowrap;
    }

    &.justifyStart {
      justify-content: flex-start;
    }

    &.justifyEnd {
      justify-content: flex-end;
    }

    &.justifyCenter {
      justify-content: center;
    }

    &.justifyBetween {
      justify-content: space-between;
    }

    &.justifyAround {
      justify-content: space-around;
    }

    &.justifyEvenly {
      justify-content: space-evenly;
    }

    &.alignStart {
      align-items: flex-start;
    }

    &.alignEnd {
      align-items: flex-end;
    }

    &.alignCenter {
      align-items: center;
    }

    &.alignStretch {
      align-items: stretch;
    }
  }

  @include gridItemsList(xl, 12);
  @include gridItemsList(lg, 12);
  @include gridItemsList(sm, 12);
  @include gridItemsList(xs, 12);

  // &.item {
    // @include gridItemsList();

    // &.xl
    // &.lg
    // &.sm
    // &.xs
  // }
}