@import "../../../../../styles/tools";

$calendarHeaderHeight: 2rem;

.Calendar {
  @include flexColumn(nowrap, space-between, center);
  width: 100%;
  height: 100%;

  & > .Calendar__header {
    @include flexRow(nowrap, space-around, center);
    width: 100%;
    height: $calendarHeaderHeight;
    // padding: 0.25rem 0;
    margin-bottom: 0.25rem;
    cursor: default;
    user-select: none;
    flex: 1 0 auto;

    & > .Calendar__header__control {
      @include flexCenter();
      height: 100%;
      cursor: pointer;
      color: $defaultColor40;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      flex: 0 1 auto;

      &:hover {
        background-color: $transparentDarkColor05;
        color: $defaultColor40;
      }

      &:active {
        background-color: $transparentDarkColor15;
        color: $defaultColor90;
      }

      &.Calendar__header__control__selected {
        background-color: $transparentDarkColor15;
        cursor: default;
      }
    }
  }

  & > .Calendar__content {
    // @include flexColumn(nowrap, space-between, center);
    width: 100%;
    height: calc(100% - #{$calendarHeaderHeight + 0.25rem});
    // padding-bottom: 0.25rem;

    &.Calendar__content__days {
      // @include flexColumn(nowrap, space-around, center);

      & > .Calendar__week {
        @include flexRow(nowrap, space-between, center);
        width: 100%;
        margin-bottom: 0.25rem;

        & > .Calendar__day {
          @include flexCenter();
          width: 2rem;
          height: 2rem;
          border-radius: 4px;
          background-color: $transparentDarkColor05;
          color: $defaultColor40;
          cursor: pointer;

          &:hover {
            background-color: $transparentDarkColor10;
            color: $defaultColor90;
          }

          &:active {
            background-color: $transparentDarkColor15;
          }

          &.Calendar__day__selected {
            background-color: $primaryColor20;
            color: $whiteColor100;
          }
        }
      }
    }

    &.Calendar__content__months {
      @include flexColumn(wrap, space-between,center);
      // padding-bottom: 0.25rem;

      & > .Calendar__month {
        @include flexCenter();
        width: calc(50% - 0.5rem);
        height: 2rem;
        border-radius: 4px;
        cursor: pointer;
        background-color: $transparentDarkColor05;
        color: $defaultColor40;

        &:hover {
          background-color: $transparentDarkColor10;
        }

        &:active {
          background-color: $transparentDarkColor15;
          color: $defaultColor90;
        }

        &.Calendar__month__selected {
          background-color: $primaryColor20;
          color: $whiteColor100;
        }
      }
    }

    &.Calendar__content__years {
      @include flexRow(wrap, space-between, center);
      overflow-x: hidden;
      overflow-y: auto;
      // padding-bottom: 0.25rem;

      & > .Calendar__year {
        @include flexCenter();
        width: calc(100% / 4 - 4px);
        padding: 0.25rem 0.5rem;
        background-color: $transparentDarkColor05;
        color: $defaultColor40;
        border-radius: 4px;
        margin-bottom: 0.25rem;
        cursor: pointer;

        &:hover {
          background-color: $transparentDarkColor10;
        }

        &:active {
          background-color: $transparentDarkColor15;
          color: $defaultColor90;
        }

        &.Calendar__year__selected {
          background-color: $primaryColor20;
          color: $whiteColor100;
        }
      }
    }
  }
}
