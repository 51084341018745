@import "../../../../styles/tools";

.CheckboxField {
  @include flexColumn(nowrap, space-between, flex-start);
  width: 100%;

  & > .CheckboxField__input {
    @include flexRow(nowrap, flex-start, center);
    width: 100%;

    & > .CheckboxField__label {
      width: calc(100% - 2rem);
      margin-right: 0.5rem;
      flex: 0 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;

      &.CheckboxField__label__disabled {
        cursor: default;
      }
    }
  }

  & > .CheckboxField__error-message {
    width: 100%;
    height: 1.25rem;
    padding: 0.25rem 0;
    color: $secondaryColor100;
    font-weight: bold;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
