@import "../../../styles/tools";

.PageContent {
  width: 100%;
  height: calc(100% - #{$headerHeight});
  position: relative;
  z-index: $pageContentZIndex;

  &.withNavBar {
    height: calc(100% - #{$headerHeight + $navHeight});
  }
}
