@import "../../../../styles/tools";

.BasicButton {
  @include flexRow(nowrap, center, center);
  background-color: $whiteColor100;
  border: 1px solid $whiteColor100;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  color: $defaultColor90;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: background-color 200ms, border-color 200ms;

  &:hover {
    background-color: $defaultColor10;
  }

  &:active {
    background-color: $defaultColor20;
    transform: scale(0.99);
  }

  &:focus {
    border: 1px solid $primaryColor60;
  }

  &.BasicButton__disabled {
    background-color: $defaultColor10;
    color: $defaultColor40;
    border-color: $defaultColor10;
    cursor: default;
  }
}
