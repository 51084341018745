@import "../../../../styles/tools";

.BasicTable {
  width: 300px;
  border: 1px solid $defaultColor20;
  border-radius: 4px;
  border-spacing: 0;
  background-color: $whiteColor100;
  font-size: $defaultFormControlFontSize;
  color: $defaultColor90;

  &.BasicTable__disabled {
    background-color: $defaultColor10;
    color: $defaultColor40;
  }
}
