@import "../../../../styles/tools";

.DateInputField {
  @include flexColumn(nowrap, space-between, flex-start);
  width: 100%;

  & > .DateInputField__error-message {
    width: 100%;
    height: 1.25rem;
    padding: 0.25rem 0;
    color: $secondaryColor100;
    font-weight: bold;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
