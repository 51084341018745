@import "../../../../styles/tools";

.BasicListItem {
  width: calc(100% - 1rem);
  padding: 0.5rem 1rem;
  margin: 0;
  background-color: $whiteColor100;
  color: $defaultColor90;
  border-bottom: 1px solid $transparentColor0;
  border-radius: 4px;
  cursor: default;

  &.BasicListItem__button {
    cursor: pointer;

    &:hover {
      background-color: $defaultColor10;
    }

    &:active {
      background-color: $defaultColor20;
    }

    &.BasicListItem__selected {
      color: $whiteColor100;
      background-color: $primaryColor20;
    }

    &.BasicListItem__disabled {
      cursor: default;
      background-color: $whiteColor100;
      color: $defaultColor40;

      &.BasicListItem__selected {
        background-color: $defaultColor10;
      }
    }
  }
}
