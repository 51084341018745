@import "../../../../../styles/tools";

.VisitTableActionMenu {

  & > .listItems {
    position: absolute;
    list-style: none;
    width: 200px;
    background-color: $whiteColor100;
    border-radius: 4px;
    box-shadow: 1px 1px 3px $shadowDefaultColor;

    & > .menuItem {
      padding: 0.25rem 1rem;
      color: $defaultColor40;
      user-select: none;
      cursor: pointer;

      &:first-of-type {
        border-radius: 4px 4px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 4px 4px;
      }

      &:not(.itemDisabled):hover {
        color: $defaultColor90;
        background-color: $defaultColor10;
      }

      &.itemDisabled {
        cursor: default;
        color: $defaultColor20;
      }
    }
  }
}
