@import "../../../styles/tools";

.NavBar {
  width: 100%;
  height: 0;
  padding: 0 1rem;
  background-color: $whiteColor100;
  opacity: 0;
  position: relative;
  z-index: $navBarZIndex;
  overflow: hidden;

  &.isOpen {
    height: $navHeight;
    opacity: 1;
    border-bottom: 1px solid $defaultColor20;
    padding: 0.5rem 1rem;
  }
}
