@import "../../styles/tools";

.Form {
  width: 35rem;
  padding: 2rem;
  position: relative;
  background-color: $whiteColor100;
  border-radius: 4px;
  @include shadowDefault();

  & > .Form__preloader {
    @include flexCenter();
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $formPreloaderZIndex;
    background-color: $transparentColor85;
    color: $primaryColor80;
    font-weight: bold;
  }
}
