@import "../../styles/tools";

.CentralSectionToolbar {
  height: $adminCentralFormToolbarHeight;
  padding: 0.5rem 1.5rem;
  margin-top: 0;
  background-color: $whiteColor100;
  border-radius: 4px;
  border: 1px solid $defaultColor20;
}
