@import "../../../../styles/tools";

.BasicSelect {
  width: $defaultFormControlWidth;
  position: relative;

  & > .BasicSelect__input {
    width: 100%;
    padding: 0.25rem 1.5rem 0.25rem 0.25rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid $defaultColor20;
    background-color: $whiteColor100;
    color: $defaultColor90;
    cursor: pointer;

    &:focus {
      border-color: $primaryColor60;
    }

    &.BasicSelect__input__invalid {
      background-color: $secondaryColor10;
      border-color: $secondaryColor100;
    }

    &.BasicSelect__input__valid {
      background-color: $validColor10;
      border-color: $validColor100;
    }

    &.BasicSelect__input__disabled {
      background-color: $defaultColor10;
      color: $defaultColor40;
      cursor: default;
    }
  }

  & > .BasicSelect__icon__container {
    @include flexCenter();
    position: absolute;
    right: 0.25rem;
    top: 0;
    width: 1rem;
    height: 100%;

    & > .BasicSelect__icon {
      transform-origin: center;
      transform: rotateZ(0);
      transition: transform 200ms;
      cursor: pointer;

      &.BasicSelect__icon__opened {
        transform: rotateZ(-90deg);
      }

      &.BasicSelect__icon__disabled {
        cursor: default;

        & > path {
          stroke: $defaultColor20;
        }
      }
    }
  }
}

.BasicSelect__options__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 16rem;
  height: 10rem;
  border-radius: 4px;
  border: 1px solid $defaultColor20;
  background-color: $whiteColor100;
  padding: 0.25rem 0;
  z-index: $optionsZIndex;

  & > .BasicSelect__options {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;

    & > .BasicSelect__item {
      padding: 0.25rem 0.5rem;
      color: $defaultColor90;

      &:hover {
        background-color: $defaultColor10;
      }

      &:active {
        background-color: $defaultColor20;
      }

      &.BasicSelect__item__selected {
        color: $whiteColor100;
        background-color: $primaryColor20;
      }
    }
  }
}
