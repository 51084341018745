$headerHeight: 5rem;
$loginToolbarWidth: 20rem;

$navHeight: 2.5rem;

$asideWidth: 20rem;

$centralSectionFormWrapperMaxWidth: 960px;

$adminCentralFormToolbarHeight: 3.5rem;

$formTitleHeight: 1.25rem;

$defaultFormControlWidth: 16rem;

$defaultFormControlFontSize: 0.83333rem;
