@import "../../styles/tools";

.FormPreloader {
  @include flexCenter();
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $transparentColor85;
  color: $primaryColor60;
  font-weight: bold;
  z-index: $formPreloaderZIndex;
}
