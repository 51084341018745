@import "../../../../styles/tools";

.PlatesListField {
  width: $defaultFormControlWidth;

  & > .PlatesListField__list {
    border: 1px solid $defaultColor20;
    border-radius: 4px;

    & .PlatesListField__empty_item {
      cursor: default;
      user-select: none;
    }
  }

  &.PlatesListField__valid {

  }

  &.PlatesListField__invalid {

  }

  &.PlatesListField__disabled {
    & > .PlatesListField__list {
      background-color: $defaultColor10;
    }
  }

  & > .PlatesListField__error-message {
    width: 100%;
    height: 1.25rem;
    padding: 0.25rem 0;
    color: $secondaryColor100;
    font-weight: bold;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
