@import "../../../../styles/tools";

.Privileges {

  & > .title {
    font-size: 1rem;
    color: $defaultColor90;
  }

  & > .content {
    @include flexRow(wrap, flex-start, flex-start);
    width: 100%;

    & .group {
      width: 15rem;
      margin: 1rem;

      & > .groupLabel {
        font-size: 1rem;
        color: $defaultColor90;
      }
    }


  }

  &.PrivilegesDisabled {
    & > .title {
      color: $defaultColor40;
    }

    & > .content {
      & .groupLabel {
        color: $defaultColor40;
      }
    }
  }
}
