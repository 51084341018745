@import "../../../../styles/tools";

.BasicTCell {
  padding: 4px;

  &.BasicTh {
    border-bottom: 1px solid $transparentColor0;

    &.BasicTh__disabled {
      border-bottom-color: $defaultColor20;
    }
  }

  &.BasicTd {


    &.BasicTd__disabled {

    }
  }

  &.BasicTCell__disabled {

  }
}
