@import "../../styles/tools";

.PlateLink {
  display: inline-block;
  @include flexCenter();
  width: 15rem;
  height: 4rem;
  overflow: hidden;
  // background-color: $whiteColor100;
  background-color: $transparentDarkColor05;
  border-radius: 4px;
  // border-bottom: 2px solid $transparentColor0;
  margin: 0.5rem;
  color: $defaultColor90;
  transition: background-color 300ms, color 300ms;

  &:hover {
    background-color: $transparentDarkColor10;
    border-bottom-color: $defaultColor20;
  }

  &:active {
    $color: $primaryColor80;
    background-color: $transparentDarkColor10;
    transform: scale(0.96);
    box-shadow: none;
  }

  & a {
    width: 100%;
    height: 100%;
    @include flexCenter();
    padding: 1rem;
    text-decoration: none;
    color: $defaultColor40;
    transition: color 300ms;

    &:hover {
      color: $defaultColor90;
    }

    &:active {
      font-weight: bold;
    }
  }
}