@import "../../../../styles/tools";

.BasicCheckbox {
  @include flexCenter();
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  outline: none;
  border: 1px solid $transparentColor0;

  & > .BasicCheckbox__icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &:focus {
    border: 1px solid $primaryColor60;
    border-radius: 4px;
  }

  &.BasicCheckbox__disabled {
    border-color: $transparentColor0;

    & > .BasicCheckbox__icon {
      cursor: default;
    }
  }
}
