$primaryColor100: rgba(16, 78, 139, 1);
$primaryColor80: rgba(24, 116, 205, 1);
$primaryColor60: rgba(28, 134, 238, 1);
$primaryColor20: rgba(108, 166, 205, 1);
$primaryColor10: rgba(141, 182, 205, 1);
$primaryColor05: rgba(224, 255, 255, 1);

$defaultColor10: rgba(220, 220, 220, 1);
$defaultColor20: rgba(184, 184, 184, 1);
$defaultColor40: rgba(120, 120, 120, 1);
$defaultColor90: rgba(31, 31, 31, 1);

$secondaryColor100: rgba(255, 0, 0, 1);
$secondaryColor10: rgba(255, 228, 225, 1);

$validColor100: rgba(0, 139, 139, 1);
$validColor10: rgba(176, 224, 230, 1);

$whiteColor100: rgba(255, 255, 255, 1);

$transparentColor0: rgba(255, 255, 255, 0);
$transparentColor10: rgba(255, 255, 255, 0.1);
$transparentColor85: rgba(255, 255, 255, 0.85);

$transparentDarkColor85: rgba(0, 0, 0, 0.85);
$transparentDarkColor35: rgba(0, 0, 0, 0.35);
$transparentDarkColor15: rgba(0, 0, 0, 0.15);
$transparentDarkColor10: rgba(0, 0, 0, 0.1);
$transparentDarkColor05: rgba(0, 0, 0, 0.05);

$shadowDefaultColor: rgba(0, 0, 0, 0.7);
