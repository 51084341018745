@import "../../../../styles/tools";

.TabLink {
  display: inline-block;
  min-width: 5rem;
  height: 100%;
  margin: 0 0.5rem;
  background-color: $whiteColor100;
  color: $defaultColor40;
  text-decoration: none;
  position: relative;
  text-align: center;

  &.isActive {
    color: $primaryColor80;

    &:after {
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      border-bottom: 2px solid $primaryColor60;
      width: 100%;
      content: ''
    }
  }
}
