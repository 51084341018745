@import "../../../styles/tools";

.BasicModal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $modalZIndex;
}
