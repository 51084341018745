@import "../../../styles/tools";

.UserProfilePageContent {
  @include flexRow(nowrap, space-between, flex-start);
  width: 100%;
  height: 100%;
  color: $defaultColor40;

  & .centralSection {
    width: 100%;
  }
}
