@import "../../../../styles/tools";

.BasicMultiSelect {
  width: $defaultFormControlWidth;
  min-height: 1.6875rem;
  position: relative;
  border: 1px solid $defaultColor20;
  border-radius: 4px;
  outline: none;
  padding: 1px 1.5rem 1px 2px;
  background-color: $whiteColor100;
  color: $defaultColor90;
  cursor: pointer;
  font-size: $defaultFormControlFontSize;

  &:focus {
    border-color: $primaryColor60;
  }

  &.BasicMultiSelect__invalid {
    background-color: $secondaryColor10;
    border-color: $secondaryColor100;
  }

  &.BasicMultiSelect__valid {
    background-color: $validColor10;
    border-color: $validColor100;
  }

  &.BasicMultiSelect__disabled {
    border-color: $defaultColor20;
    background-color: $defaultColor10;
    color: $defaultColor40;
    cursor: default;
  }

  & > .BasicMultiSelect__content__container {
    width: 100%;

    & > .BasicMultiSelect__value__item {
      display: inline-block;
      border: 1px solid $defaultColor10;
      border-radius: 0.5rem;
      padding: 0 1.25rem 0 0.5rem;
      margin: 1px 0.25rem;
      color: $defaultColor90;
      background-color: $whiteColor100;
      position: relative;

      & > .BasicMultiSelect__value__icon__container {
        opacity: 0;
        transition: opacity 200ms;
        @include flexCenter();
        position: absolute;
        width: 1rem;
        height: 100%;
        top: 0;
        right: 2px;

        & > svg {
          & > g > path {
            stroke: $defaultColor40;
          }

          &:hover {
            & > g > path {
              stroke: $defaultColor90;
            }
          }
        }
      }

      &:hover {
        background-color: $defaultColor10;

        & > .BasicMultiSelect__value__icon__container {
          opacity: 1;
        }
      }

      &.BasicMultiSelect__value__item__disabled {
        background-color: $defaultColor10;
        color: $defaultColor40;

        & > .BasicMultiSelect__value__icon__container {
          display: none;
        }
      }
    }
  }

  & > .BasicMultiSelect__icon__container {
    @include flexCenter();
    position: absolute;
    right: 0.25rem;
    top: 0;
    width: 1rem;
    height: 100%;

    & > .BasicMultiSelect__icon {
      transform-origin: center;
      transform: rotateZ(0);
      transition: transform 200ms;
      cursor: pointer;

      &.BasicMultiSelect__icon__opened {
        transform: rotateZ(-90deg);
      }

      &.BasicMultiSelect__icon__disabled {
        cursor: default;

        & > path {
          stroke: $defaultColor20;
        }
      }
    }
  }
}

.BasicMultiSelect__options__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 16rem;
  height: 10rem;
  border-radius: 4px;
  border: 1px solid $defaultColor20;
  background-color: $whiteColor100;
  padding: 0.25rem 0;
  z-index: $optionsZIndex;

  & > .BasicMultiSelect__options {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;

    & > .BasicMultiSelect__item {
      padding: 0.25rem 0.5rem;
      color: $defaultColor90;
      cursor: pointer;
      font-size: $defaultFormControlFontSize;

      &:hover {
        background-color: $defaultColor10;
      }

      &:active {
        background-color: $defaultColor20;
      }

      &.BasicMultiSelect__item__selected {
        color: $whiteColor100;
        background-color: $primaryColor20;
      }
    }

    & > .BasicMultiSelect__item__empty {
      padding: 0.25rem 0.5rem;
      color: $defaultColor90;
    }
  }
}
