@import "../../../../styles/tools";

.BasicIconButton {
  @include flexCenter();
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  background-color: $transparentColor0;
  border: 1px solid $transparentColor0;
  border-radius: 50%;
  outline: none;
  color: $defaultColor90;
  cursor: pointer;
  transition: background-color 200ms;

  &:hover {
    background-color: $transparentDarkColor15;
  }

  &:active {
    background-color: $transparentDarkColor35;
  }

  &.BasicIconButton__disabled {
    cursor: default;
    background-color: $transparentColor10;
    color: $defaultColor40;
  }
}
