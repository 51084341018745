@import "../../styles/tools";

.CentralSectionForm {
  width: 100%;
  height: calc(100% - #{$adminCentralFormToolbarHeight});
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: none;
  border: 1px solid $defaultColor20;
  border-radius: 4px;

  & > .sectionWrapper {
    height: 100%;
    width: 100%;
    max-width: $centralSectionFormWrapperMaxWidth;
    margin: 0 auto;
  }
}
