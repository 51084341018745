@import "../../../../styles/tools";

.UlcerDetailsList {
  & .ulcerItemDetail {
    display: inline-block;
    margin: 0 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.visit_number {
      width: 7rem;
    }

    &.sizes {
      width: 15rem;
    }

    &.square {
      width: 12rem;
    }

    &.difference {
      width: 10rem;
    }
  }
}


