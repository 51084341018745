@import "../../../../styles/tools";

.BasicLabel {
  display: inline-block;
  padding: 0.25rem;
  font-size: 0.85rem;
  font-weight: bold;
  color: $defaultColor90;
  user-select: none;

  &.BasicLabel__disabled {
    color: $defaultColor40;
  }
}
