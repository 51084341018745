@import "../../../../styles/tools";

.PlatesListItem {
  @include flexRow(nowrap, space-between, center);
  width: 100%;
  min-height: 2.625rem;
  font-size: $defaultFormControlFontSize;
  color: $defaultColor90;
  border: 1px solid $defaultColor10;
  border-radius: 4px;
  padding: 0.25rem;

  &:hover {
    background-color: $defaultColor10;
  }

  & > .PlatesListItem__content_box {
    width: calc(100% - 8rem);
    flex: 1 1 auto;
  }

  & > .PlatesListItem__toolbar {
    @include flexRow(nowrap, flex-end, center);
    width: 8rem;
    flex: 1 1 auto;
  }

  &.PlatesListItem__selected {
    border-color: $primaryColor60;
  }

  &.PlatesListItem__disabled {
    color: $defaultColor40;
    background-color: $defaultColor10;
    border-color: $transparentColor0;

    & > .PlatesListItem__content_box {
      width: 100%;
    }
  }
}
