@import "../../../../styles/tools";

.VisitTable {
  @include flexColumn(nowrap, space-between, flex-start);
  width: 100%;
  user-select: none;

  & .row {
    &:hover {
      background-color: $defaultColor10;
    }

    &.selectedRow {
      background-color: $primaryColor20;
      color: $whiteColor100;
    }
  }



  & > .VisitTableErrorMessage {
    width: 100%;
    height: 1.25rem;
    padding: 0.25rem 0;
    color: $secondaryColor100;
    font-weight: bold;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
