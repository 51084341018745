@import "../../styles/tools";

.Page {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: $pageZIndex;
  background-color: $defaultColor10;
  opacity: 1;
  transition: opacity 2000ms;

  &.is__hidden {
    opacity: 0;
  }
}
