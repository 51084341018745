@import "../../../styles/tools";

.AdminPageContent {
  width: 100%;
  height: 100%;
  background-color: $whiteColor100;
  & > .content {
    @include flexRow(wrap, flex-start, flex-start);
    width: 100%;
  };
}
