@import "../../../styles/tools";

.ErrorModal {
  @include flexCenter();
  background-color: $transparentDarkColor85;

  & .errorMessage {

  }

  & .errorStatus,
  & .errorDateTime
  {
    font-size: 0.85rem;
    font-style: italic;
  }
}
