@import "../../../../styles/tools";

.BasicTr {

  &.BasicTr__header {

  }

  &.BasicTr__disabled {
    background-color: $defaultColor10;
  }
}
