@import "../../../styles/tools";

.Header {
  @include flexRow(nowrap, space-between, center);
  width: 100%;
  height: $headerHeight;
  padding: 1rem 2rem;
  background-color: $primaryColor100;

  & > .menuButton {
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;

    & > svg {
      &:active {
        transform: scale(0.96);
      }
    }
  }

  & > .title {
    display: block;
    width: calc(100% - 4rem);
    color: $whiteColor100;
    font-size: 2rem;
    font-weight: bold;
    font-family: Roboto, Arial, sans-serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.withLoginToolbar {
    & > .title {
      width: calc(100% - 4rem - #{$loginToolbarWidth});
    }

    & > .loginToolbar {
      @include flexRow(nowrap, flex-end, center);
      width: $loginToolbarWidth;
      color: $whiteColor100;

      & > .loggedLabel {
        margin-right: 1rem;
      }

      & > .headerToolbarButton {
        & > button {
          width: 5rem;
          height: 2rem;
          padding: 0.5rem;
          outline: none;
          border: 1px solid $transparentColor10;
          border-radius: 4px;
          background-color: $transparentColor10;
          color: $whiteColor100;
          cursor: pointer;
          transition: background-color 200ms;

          &:hover {
            background-color: $primaryColor80;
          }

          &:active {
            background-color: $primaryColor60;
          }
        }
      }
    }
  }
}
