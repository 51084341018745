@import "../../../../styles/tools";

$fadeHeight: 2rem;

.BasicList {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  & > .BasicList__list {
    width: 100%;
    height: 100%;
    list-style: none;
  }

  & > .topFade,
  & > .bottomFade {
    height: $fadeHeight;
    width: 100%;
    position: absolute;
    left: 0;
  }

  & > .topFade {
    top: 0;
    background-image: linear-gradient(to bottom, $whiteColor100, $transparentColor0);
  }

  & > .bottomFade {
    bottom: 0;
    background-image: linear-gradient(to top, $whiteColor100, $transparentColor0);
  }
}
