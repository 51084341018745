@mixin displayFlex($direction: row, $wrap: wrap, $justifyContent: flex-start, $alignItems: flex-start) {
  display: flex;
  flex-flow: $direction $wrap;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flexRow($wrap: wrap, $justifyContent: flex-start, $alignItems: flex-start) {
  @include displayFlex(row, $wrap, $justifyContent, $alignItems);
}

@mixin flexColumn($wrap: wrap, $justifyContent: flex-start, $alignItems: flex-start) {
  @include displayFlex(column, $wrap, $justifyContent, $alignItems);
}

@mixin flexCenter() {
  @include flexColumn(nowrap, center, center);
}

@mixin shadowDefault() {
  box-shadow: 3px 3px 3px $shadowDefaultColor;
}

@mixin gridItem($columns: 12, $colCount: 12, $marginsH: 1rem) {
  width: calc(#{$columns / $colCount} * 100% - #{$marginsH});
  margin-left: calc(#{$marginsH / 2});
  margin-right: calc(#{$marginsH / 2});
}

@mixin gridItemsList($mediaSizeName: lg, $colCount: 12) {
  @for $i from 1 to ($colCount + 1) {
    &.col-#{$mediaSizeName}-#{$i} {
      @include gridItem($i, $colCount);
    }
  }
}
