@import "../../../styles/tools";

.pageContent {
  @include flexColumn(nowrap, flex-start, center);
  width: 100%;
  height: 100%;

  & > .loginForm {
    margin-top: 1rem;
    width: 20rem;
  }
}
